// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ps__rail-x {
  display: none !important;
}
.ps__thumb-x {
  display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/layout/MainLayout/Header/ProfileSection/index.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B","sourcesContent":[".ps__rail-x {\n  display: none !important;\n}\n.ps__thumb-x {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
