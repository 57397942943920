// action - state management
import * as actionTypes from '../actions';

export const initialState = {
  isDirty: false,
  chatflow: null,
};

// ==============================|| CANVAS REDUCER ||============================== //

const canvasReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DIRTY:
      return {
        ...state,
        isDirty: true,
      };
    case actionTypes.REMOVE_DIRTY:
      return {
        ...state,
        isDirty: false,
      };
    case actionTypes.SET_CHATFLOW:
      return {
        ...state,
        chatflow: action.chatflow,
      };
    default:
      return state;
  }
};

export default canvasReducer;
