import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './reducers/customizationReducer';
import canvasReducer from './reducers/canvasReducer';
import notifierReducer from './reducers/notifierReducer';
import dialogReducer from './reducers/dialogReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  canvas: canvasReducer,
  notifier: notifierReducer,
  dialog: dialogReducer,
});

export default reducer;
