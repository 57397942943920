import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/loading/Loadable';

// chatflows routing
const Chatflows = Loadable(lazy(() => import('views/chatflows')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Chatflows />,
    },
    {
      path: '/chatflows',
      element: <Chatflows />,
    },
  ],
};

export default MainRoutes;
