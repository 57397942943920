import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/loading/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// canvas routing
const Canvas = Loadable(lazy(() => import('views/canvas')));

// ==============================|| CANVAS ROUTING ||============================== //

const CanvasRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/canvas',
      element: <Canvas />,
    },
    {
      path: '/canvas/:id',
      element: <Canvas />,
    },
  ],
};

export default CanvasRoutes;
