import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

import Loadable from 'ui-component/loading/Loadable';
import { lazy, useEffect } from 'react';
const Canvas = Loadable(lazy(() => import('views/canvas')));
// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);
  const location = useLocation();
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  useEffect(() => {
    const query = useQuery();
    const token = query.get('token');
    const botId = query.get('botId');
    const ownerId = query.get('ownerId');
    const transformerId = query.get('transformerId');
    if (token) sessionStorage.setItem('auth', token);
    if (botId) sessionStorage.setItem('botId', botId);
    if (ownerId) sessionStorage.setItem('ownerId', ownerId);
    if (transformerId) sessionStorage.setItem('transformerId', transformerId);
    // Validate and use the token as needed
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Canvas />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
