import { createPortal } from 'react-dom';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import moment from 'moment';
import axios from 'axios';

const fetchLatestVer = async ({ api }) => {
  let apiReturn = await axios
    .get(api)
    .then(async function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.error(error);
    });
  return apiReturn;
};

const AboutDialog = ({ show, onCancel }) => {
  const portalElement = document.getElementById('portal');

  const [data, setData] = useState({});

  useEffect(() => {
    if (show) {
      const fetchData = async (api) => {
        let response = await fetchLatestVer({ api });
        setData(response);
      };

      fetchData('https://api.github.com/repos/FlowiseAI/Flowise/releases/latest');
    }
  }, [show]);

  const component = show ? (
    <Dialog
      onClose={onCancel}
      open={show}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ fontSize: '1rem' }} id="alert-dialog-title">
        Flowise Version
      </DialogTitle>
      <DialogContent>
        {data && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Latest Version</TableCell>
                  <TableCell>Published At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <a target="_blank" rel="noreferrer" href={data.html_url}>
                      {data.name}
                    </a>
                  </TableCell>
                  <TableCell>{moment(data.published_at).fromNow()}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
    </Dialog>
  ) : null;

  return createPortal(component, portalElement);
};

AboutDialog.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default AboutDialog;
