// constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const maxScroll = 100000;
export const baseURL =
  process.env.NODE_ENV === 'production'
    ? window.location.origin
    : window.location.origin.replace(':8080', ':3000');
export const uiBaseURL = window.location.origin;
